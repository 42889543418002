(function($){
    var app;
    app = {
      init: function() {
        this._navbar();
        this._fixedHeader();
        this._validationJS();
        this._lineHeight();
        this._slider();
        this._sliderProduct();
        this._clickByHash();
        this._search();
        this._fadeUpLoad();
        this._select2();
        this._tabs();
        this._dropDown();
      },
      _dropDown: function() {
        $("#navbarHeader").niceScroll('.navbarHeader--wrapper');
        $(window).on('load', function() {
          $('.nav-link.has-child').on('click', function(e) {
            setTimeout(function() {
              $("#navbarHeader").getNiceScroll().remove();
              $("#navbarHeader").niceScroll('.navbarHeader--wrapper').resize();
            }, 500);
            e.preventDefault();
            var $parent = $(this).parents('.navbar-nav');
            
            if($(this).hasClass('is-active')) {
              $(this).removeClass('is-active').next('.nav-lavel2').slideUp();
              window.location = $(this).attr('href');
              return false;
            }
            
            $parent.find('.nav-link.is-active').removeClass('is-active').next('.nav-lavel2').slideUp();
            $(this).addClass('is-active').next('.nav-lavel2').slideDown();
          });
          
        });
      },
      _tabs: function() {
        $('#pills-tab a').on('click', function (e) {
          e.preventDefault()
          $(this).tab('show');
        });
      },
      _select2: function() {
        if ($(".js-select2").length) {
          $(".js-select2").select2({
            minimumResultsForSearch: Infinity,
            width: '100%'
          });
        }
      },
      _fadeUpLoad: function() {
        $(window).on('load scroll', function() {
          var $fadeUpElements = $('.fadeUp');
          var $top = $(window).scrollTop();
          if ($fadeUpElements.length) {
            $fadeUpElements.each(function(index, el) {
              if($(window).width() < 1200 && index < 2) {
                $(this).addClass('is-animated');
              }
              var $topElement = $(this).offset().top - ($(window).outerHeight());
              if ($top >= $topElement) {
                $(this).addClass('is-animated');
              }
            });
          }
        });
        
      },
      _search: function() {
        $('#btn-search').on('click', function(e) {
          e.preventDefault();
          $('body').addClass('is-clipped');
          $('.s-search').addClass('is-opened');
        });

        $('.close-search').on('click', function(e) {
          e.preventDefault();
          $('body').removeClass('is-clipped');
          $('.s-search').removeClass('is-opened');
        });

        

        $('#form-search, #form-search-mobile').on('keyup', '#search, #search-mobile', function() {
          if($(this).val().length > 2) {
            $(this).parent().find('.reset-search').show();
          } else {
            $(this).parent().find('.reset-search').hide();
          }
        }).on('change', '#search, #search-mobile', function() {
          
          if($(this).val() != '') {
            $(this).parent().find('.reset-search').show();
          } else {
            $(this).parent().find('.reset-search').hide();
          }
         
        });

        
        $('.reset-search').on('click', function(e) {
          e.preventDefault();
          $('#search').val('');
          $('#search-mobile').val('');
          $(this).parent().find('.reset-search').hide();
        });

        

        $('#form-search-mobile').on('focus', '#search-mobile', function() {
          $('#navMenu').fadeOut(200);
        });
        $('#form-search-mobile').on('blur', '#search-mobile', function() {
          $('#navMenu').fadeIn(200);
        });

        
        
      },
      _sliderProduct: function() {
        if($('.sliderExpoProduct').length) {
          
          var $carousel = $('.sliderExpoProduct');
          var carousel=[];
          $carousel.each(function( index ) {
            $this = $(this);
            $this.parents('.container--sliderExpoProduct').find('.produit-card').hide();
            $listItems = $this.find('img').length;
            $wrapperDots = $('.sliderExpoProduct-dots');
            $textTilte = "1/" + $listItems;
            $this.parent().append('<ul class="sliderExpoProduct-dots">');
            $this.parents('.container--sliderExpoProduct').find('.slick-prev-title').text($textTilte);
            for(var i = 0; i < $listItems; i++) {
              var $class = '';
              if(i == 0) {
                var $class = 'active';
                $this.parents('.container--sliderExpoProduct').find('.produit-card:eq(' + i + ')').show();
              }
              $this.parent().find('.sliderExpoProduct-dots').append('<li class="' + $class + '"data-id=' + i +'></li>');
            }
            
            var $carouselWidth = $this.width();
            var vui = $carouselWidth / 2.5;
            carousel[index] = $this.waterwheelCarousel({
              flankingItems: $listItems,
              // startingItem: 1,
              separation : vui,
              opacityMultiplier: 0.3,
              movedToCenter: function ($item) {
                var $index = $item.attr('data-index');
                $index = parseInt($index);
                $parent = $item.parents('.container--sliderExpoProduct');
                $listItems = $item.parents('.container--sliderExpoProduct').find('img').length;
                $parent.find('.sliderExpoProduct-dots li').removeClass('active');
                $parent.find('.sliderExpoProduct-dots li:eq(' + $index + ')').addClass('active');
                $textTilte = (parseInt($index)+1) + "/" + $listItems;
                $parent.find('.slick-prev-title').text($textTilte);
                $parent.find('.sliderExpoProduct--cards .produit-card').hide();
                $parent.find('.sliderExpoProduct--cards .produit-card:eq(' + $index + ')').show();
              }
            });
            var resizeTimer;

            $(window).on('resize', function(e) {
              clearTimeout(resizeTimer);
              resizeTimer = setTimeout(function() {
                var $carouselWidth = $carousel.width();
                var ui = $carouselWidth / 2.5;
                var imageWidth = $carouselWidth * 0.4;
                var imageHeight = imageWidth * 1.4893;
                carousel[index].find('img').removeData()
                carousel[index].find('img').attr('style','');
                  carousel[index].reload({
                    flankingItems: $listItems,
                    separation : ui,
                    opacityMultiplier: 0.3,
                    movedToCenter: function ($item) {
                      var $index = $item.attr('data-index');
                      $index = parseInt($index);
                      $parent = $item.parents('.container--sliderExpoProduct');
                      $listItems = $parent.find('img').length;
                      $parent.find('.sliderExpoProduct-dots li').removeClass('active');
                      $parent.find('.sliderExpoProduct-dots li:eq(' + $index + ')').addClass('active');
                      $textTilte = (parseInt($index)+1) + "/" + $listItems;
                      $parent.find('.slick-prev-title').text($textTilte);
                      $parent.find('.sliderExpoProduct--cards .produit-card').hide();
                      $parent.find('.sliderExpoProduct--cards .produit-card:eq(' + $index + ')').show();
                    }
                  });
                  
              }, 250);
              
            });

            //a faire

            $('.container--sliderExpoProduct .slickPrev').bind('click',  function () {
              var i = $(this).parents('.container--sliderExpoProduct').data('index');
              carousel[i].prev();
              return false;
            });

            $('.slickNext').bind('click', function () {
              var i =$(this).parents('.container--sliderExpoProduct').data('index');
              carousel[i].next();
              return false;
            });

            
            
          });

          

          $('.sliderExpoProduct-dots').on('click', 'li', function() {
            var index = $(this).attr('data-id');
            $(this).parents('.container--sliderExpoProduct').find('.sliderExpoProduct img:eq(' + index + ')').trigger('click');
          });

          
          
        }
      },
      _slider: function() {
        if($('.sliderDots-byOne--item').length > 1) {
          $('.sliderDots-byOne').slick({
            infinite: true,
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }

        if($('.sliderExpoProductMiel').length) {
          $('.sliderExpoProductMiel').on('init afterChange', function(event, slick, currentSlide, nextSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            $(this).next('.sliderExpoProduct--container').find('.nav-slick-arrow .slick-prev-title').text(i + '/' + slick.slideCount);
            $(this).next().find('.produit-card').hide();
            $(this).next().find('.produit-card[data-id=' + i + ']').show();
          });
          $('.sliderExpoProductMiel').each(function() {
            $(this).slick({
              dots: false,
              arrows: false,
              speed: 200,
              slidesToShow: 1,
              easing: 'linear',
              cssEase: 'ease-out',
              adaptiveHeight: true,
              responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    arrows: false,
                    dots: true,
                  }
                }
              ]
            });
          });

          $('button.slickPrev').on('click', function() {
            $(this).parents('.container--sliderExpoProduct').find('.sliderExpoProductMiel').slick('slickPrev');
          });
          $('button.slickNext').on('click', function() {
            $(this).parents('.container--sliderExpoProduct').find('.sliderExpoProductMiel').slick('slickNext');
          });
        }

        throttle
        function throttle(fn, frequency) {
          'use strict';
        
          frequency = frequency || 100;
          var timeout = false;
        
          return function() {
            if (timeout) {
              return;
            }
        
            timeout = setTimeout(function() {
              timeout = false;
            }, frequency);
        
            fn.apply(this, arguments);
          };
        }


        if ($('.sliderNumber-byOne--item').length) {
          $sliderOne = $('.sliderNumber-byOne');
           $sliderOne.on('init afterChange', function(event, slick, currentSlide, nextSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            var $this = $(this).find('.slick-active');
            $(this).find('.slick-prev-title').text(i + '/' + slick.slideCount);
          });
          $sliderOne.slick({
            arrows: false,
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: false,
            fade: true
          });
          
          $('button.slickPrev').on('click', function() {
            $sliderOne.slick('slickPrev');
          });
          $('button.slickNext').on('click', function() {
            $sliderOne.slick('slickNext');
          });



          
            function equalizeHeights() {
              $('.sliderNumber-byOne').each(function(){  
                var highestBox = 0;
                $('img', this).each(function(){
                    $(this).height('auto');
                    if($(this).height() > highestBox) 
                       highestBox = $(this).height(); 
                });  
                  $('img',this).height(highestBox);
                });  
            }
            equalizeHeights();
            var resizeTimer;

            $(window).on('resize', function(e) {

              clearTimeout(resizeTimer);
              resizeTimer = setTimeout(function() {

                equalizeHeights();
                        
              }, 250);

            });
          

        }


        
        if($('.slider-reference').length) {
          $('.slider-reference').on('init afterChange', function(event, slick, currentSlide, nextSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            var $this = $(this).find('.slick-active');
            $(this).next('.nav-slick-arrow').find('.slick-prev-title').text(i + '/' + slick.slideCount);
          });
          $('.slider-reference').slick({
            infinite: false,
            dots: false,
            arrows: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1025,
                settings: {
                  slidesToShow: 4,
                }
              },
              {
                breakpoint: 801,
                settings: {
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 360,
                settings: {
                  slidesToShow: 1,
                  prevArrow: '<button type="button" class="slick-prev"></button><span class="slick-prev-title">Previous</span>',
                  // nextArrow: '<button type="button" class="slick-next"></button><span class="slick-next-title">Next</span>',
                  appendArrows: '.nav-slick-arrow',
                }
              }
            ]
          });
        }
      },
      _clickByHash: function() {
        $(document).on('click', 'a[href^="#"]', function (e) {
          $headerHeight = $('header').outerHeight();
          e.preventDefault();
          $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top
          }, 1000);
        });
      },
      _calculLineHeight: function($cibleArgument) {
        var $cible = $($cibleArgument);
        $.each($cible, function(key, value) {
          var $lh = (parseFloat($(value).find('.p').css('line-height')) * 3);
          var $h = $(value).innerHeight();
          if ($h > $lh) {
            $(value).append('<span> [...]</span>');
            $(value).css({'height': $lh - 2}).addClass('is-hidden');
          }
          $(value).on('click', 'span', function() {
            $(this).parents($cibleArgument).toggleClass('is-hidden');
            $(this).hide();
          });
        });
      },
      _lineHeight: function() {
        // this method need _calculLineHeight function
        if ($(window).width() < 768) {
          this._calculLineHeight('.text-lineHeight');
        }
      },
      _fixedHeader: function() {
        $(window).on('load scroll', function() {
            if ($(window).scrollTop() > 0) {
              $('header').addClass('is-sticky');
            } else {
              $('header').removeClass('is-sticky');
            }
        });
      },
      _navbar: function() {
        
        $('.navbar-toggler').on('click', function() {
          $('#navbarHeader').addClass('is-opened');
          $('.c-nav__close').addClass('is-active');
          $('body').addClass('is-clipped');
        });
        $('.c-nav__close').on('click', function(e) {
          e.preventDefault();
          $(this).removeClass('is-active');
          $('#navbarHeader').removeClass('is-opened');
          $('body').removeClass('is-clipped');
        });
        $('.navbar-toggler.closed').on('click', function() {
          $('#navbarHeader').removeClass('is-opened');
          $('body').removeClass('is-clipped');
          $('.c-nav__close').removeClass('is-active');
        });

        $(window).on('load', function() {
          $('body').addClass('is-loaded');
        });
      },
      _validationJS: function() {
        $.validator.addMethod("customemail", 
          function(value, element) {
            return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);
          }, 
          "Veuillez fournir une adresse électronique valide."
        );
  
        
        if ($('#form-newsletter').length) {
          $('#form-newsletter').validate({
            rules: {
              "email": {
                required: true,
                'customemail': true
              },
            },
            highlight: function(element, errorClass, validClass) {

              $(element).parent('.form-group').addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function(element, errorClass, validClass) {
              $(element).parent('.form-group').removeClass(errorClass).addClass(validClass);
            },
          });
        }
        if ($('#form-search').length) {
          $('#form-search').validate({
            rules: {
              search: {
                required: true,
                minlength: 3
              }
            }
          });
        }
        if ($('#form-search-mobile').length) {
          $('#form-search-mobile').validate({
            rules: {
              search: {
                required: true,
                minlength: 3
              }
            }
          });
        }
        if ($('#form-contact').length) {
          $('#form-contact').validate({
            rules: {
              "nom": "required",
              "prenom": "required",
              "mail": {
                required: true,
                'customemail': true
              },
              "tel": {
                number: true,
                required: true
              },
              "message": "required",
              "checkContactForm": 'required'
            },
          });
        }

        if ($('#form-contact-professionnel').length) {
          $('#form-contact-professionnel').validate({
            rules: {
              "activite": "required",
              "nom": "required",
              "prenom": "required",
              "mail": {
                required: true,
                'customemail': true
              },
              "tel": {
                number: true,
                required: true
              },
              "message": "required",
              "checkContactProForm": 'required'
            },
          });
        }

        
        
      }
    };
  
    app.init();
  
  
  }(jQuery));